import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { News } from '@sportyano/core/models/account/news';
import { LanguagesService } from '@sportyano/core/services/language services/languages.service';
import { NewsService } from '@sportyano/core/services/playerProfile/news.service';
import { ToasterMessagesService } from '@sportyano/core/services/toaster-messages/toaster-messages.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-new-news-card',
  templateUrl: './new-news-card.component.html',
  styleUrl: './new-news-card.component.scss'
})
export class NewNewsCardComponent {
  @Output() onDelete: EventEmitter<void> = new EventEmitter<void>();
  @Output() onEdit: EventEmitter<void> = new EventEmitter<void>();
  @Input() item: News
  lang : string =''
  // private _toaster = inject(ToasterMessagesService)
  // private _confirmationService = inject(ConfirmationService);
	private _translateService: TranslateService = inject(TranslateService)
  constructor(private services: NewsService , private languageServices : LanguagesService) {
 this.languageServices.language$.subscribe((res)=>this.lang = res)
   }

deleteNews() {
   
        this.onDelete.emit()
  }
	
}
