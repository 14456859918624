<ng-container [ngSwitch]="adLayout">
	<div
		*ngSwitchCase="'vertical'"
		class="ad-section vertical w-full"
		[ngStyle]="getStyles('vertical')"
	></div>
	<div
		*ngSwitchDefault
		class="ad-section w-full"
		[ngStyle]="getStyles('horizontal')"
	></div>
</ng-container>
