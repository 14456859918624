<div class="bg-semiLightGray rounded-2xl relative">
	@if(showMenu){
	<div
		class="flex flex-row justify-end absolute top-4 right-4 z-10 hover:cursor-pointer"
		(click)="isMenuOpened = !isMenuOpened"
	>
		<div class="bg-textYellow w-2 h-2 rounded-full"></div>
		<div class="bg-textYellow w-2 h-2 mx-1 rounded-full"></div>
		<div class="bg-textYellow w-2 h-2 rounded-full"></div>
	</div>
	<div
		class="__dropdown-menu z-20 bg-darkBlue transition-all rounded-lg absolute top-16 right-4 w-fit"
		*ngIf="isMenuOpened"
	>
		<div
			(click)="isMenuOpened = false"
			class="__notification-item cursor-pointer account-management hover:bg-textYellow transition-all w-[120px] py-2 first-of-type:mt-2 last-of-type:pt-2 last-of-type:mb-2 max-w-full"
		>
			<a class="flex px-4 text-fontColor capitalize text-sm font-normal">
				{{ "account.account_management.news_Media.Edit" | translate }}
			</a>
		</div>

		<div
			(click)="isMenuOpened = false"
			class="__notification-item cursor-pointer account-management hover:bg-textYellow transition-all w-[120px] py-2 first-of-type:mt-2 last-of-type:pt-2 last-of-type:mb-2 max-w-full"
		>
			<a class="flex px-4 text-fontColor capitalize text-sm font-normal">
				{{ "account.account_management.news_Media.Delete" | translate }}
			</a>
		</div>
	</div>
	}
	<div class="news-card flex gap-2 justify-start">
		<div class="news-card-image-container flex h-100 w-2/5 p-2">
			<img
				[src]="news?.image_url"
				class="w-full rounded-2xl max-h-full object-cover"
			/>
		</div>

		<div class="w-3/5">
			<div class="py-6 px-10 text-sm text-fontColor">
				<p>{{ news?.content_en }}</p>
			</div>
		</div>
	</div>
</div>
