import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
	selector: "[appArabicOnly]",
})
export class ArabicOnlyDirective {
	constructor(private el: ElementRef) {}

	@HostListener("input", ["$event"]) onInputChange(event: any) {
		const initialValue = this.el.nativeElement.value;
		const arabicOnlyRegex = /^[\u0621-\u064A0-9\s]*$/;
		if (!arabicOnlyRegex.test(initialValue)) {
			const newValue = initialValue.replace(/[^\u0621-\u064A0-9\s]/g, "");
			this.el.nativeElement.value = newValue;
			event.preventDefault();
		}
	}
}
