import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class UrlService {
	public getWebsiteUrl(): string {
		const url = new URL(window.location.href);
		return url.hostname.replace("www.", "");
	}
}
