<div class="location-card-container flex flex-col h-full">
	<!-- Upper row: Map -->
	<div class="map-container">
		<div class="map-frame">
			<div id="map"></div>
		</div>
	</div>

	<!-- Bottom row: Details -->

	<!-- Address -->
	<div class="grid grid-cols-1 justify-center p-2">
		<p class="text-s text-fontColor break-all">{{ locationData.address }}</p>
	</div>

	<!-- Mobile Number and Get Direction Button -->
	<!-- <div class="details-container grid grid-cols-2 items-center justify-between p-2"> -->
	<div class="details-container">
		<!-- Mobile Number (left-aligned) -->
		<!-- <div *ngIf="userAuthenticationState">
			<p class="text-s text-textYellow">{{ locationData.mobile_number }}</p>
		</div> -->
		<!-- <div *ngIf="!userAuthenticationState"
	  		(click)="showPhoneNumber()"
		   class="w-30 h-10 bg-semiLightGray border border-fontColor text-base text-fontColor rounded-md shadow-md hover:border-lightBlue hover:bg-lightBlue hover:text-fontColor flex items-center justify-center cursor-pointer">
		  {{ "SPORT_ACADEMIES.show_contact" | translate }}
		
	  </div> -->

		<!-- Get Direction Button (right-aligned) -->
		<div>
			<a href="{{ locationData.location_url }}" target="_blank"
				[ngClass]="{'pointer-events-none': !locationData.location_url}"
				class="w-30 h-10 bg-semiLightGray border border-fontColor text-base text-fontColor rounded-md shadow-md hover:border-lightBlue hover:bg-lightBlue hover:text-fontColor flex items-center justify-center">
				{{ "SPORT_ACADEMIES.Get_Direction" | translate }}
			</a>
		</div>
	</div>
	<div class="text-sm text-fontColor break-all my-3">
		{{"contact.customerService"|translate}} <a href="tel:01101003140" class="tel">01101003140</a>
		
	</div>
</div>