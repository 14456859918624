import { Component } from '@angular/core';

@Component({
  selector: 'app-single-training-program',
  templateUrl: './single-training-program.component.html',
  styleUrl: './single-training-program.component.scss'
})
export class SingleTrainingProgramComponent {

}
