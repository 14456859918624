import {
	Directive,
	ElementRef,
	Input,
	Renderer2,
	HostListener,
	OnInit,
	AfterViewInit,
} from "@angular/core";

@Directive({
	selector: "[TextLimiter]",
	standalone: true,
})
export class TextLimiterDirective {
	@Input() maxChars: number = 20; // Default max characters
	@Input() maxLines: number = 0; // Default max lines
	@Input() seeMoreText: string = "... see more"; // Default see more text

	private originalText: string = "";
	private isTruncated: boolean = false;

	constructor(private el: ElementRef, private renderer: Renderer2) {}

	ngOnInit() {}

	ngAfterViewInit() {

		this.originalText = this.el.nativeElement.innerText;
		this.applyTextLimiter();
	}

	private applyTextLimiter() {
		if (this.maxChars > 0) {
			this.truncateByChars();
		} else if (this.maxLines > 0) {
			this.truncateByLines();
		}
	}

	private truncateByChars() {
		if (this.originalText.length > this.maxChars) {
			const truncatedText =
				this.originalText.substring(0, this.maxChars) +
				this.seeMoreText;
			this.renderer.setProperty(
				this.el.nativeElement,
				"innerText",
				truncatedText
			);
			this.isTruncated = true;
		}
	}

	private truncateByLines() {
		const element = this.el.nativeElement;
		this.renderer.setStyle(element, "display", "-webkit-box");
		this.renderer.setStyle(element, "-webkit-line-clamp", this.maxLines);
		this.renderer.setStyle(element, "-webkit-box-orient", "vertical");
		this.renderer.setStyle(element, "overflow", "hidden");

		const truncatedText = this.originalText;
		const fullText = this.originalText + this.seeMoreText;

		const checkTruncation = () => {
			if (element.scrollHeight > element.clientHeight) {
				this.renderer.setProperty(
					element,
					"innerText",
					truncatedText.substring(
						0,
						truncatedText.length - this.seeMoreText.length
					) + this.seeMoreText
				);
				this.isTruncated = true;
			}
		};

		setTimeout(checkTruncation, 0); // Ensure DOM update
	}

	@HostListener("click") onClick() {
		if (this.isTruncated) {
			this.renderer.setProperty(
				this.el.nativeElement,
				"innerText",
				this.originalText
			);
			this.isTruncated = false;
		} else {
			this.applyTextLimiter();
		}
	}
}
