<div
	class="cart p-1 rounded-2xl overflow-hidden block h-full"
	[ngClass]="{
		'cursor-pointer': !!route()
	}"
>
	<div
		class="container flex gap-3 flex-wrap lg:flex-nowrap p-1 relative"
		[ngClass]="axis() === 'vertical' ? 'flex-col items-center' : ''"
		(click)="navigateTo(route())"
	>
		<div
			class="relative rounded-lg m-2 overflow-hidden cursor-pointer mx-auto lg:mx-0"
			[ngClass]="{
				'player-aspect': type === 'player',
				'academyandplayground-aspect': type === 'academyandplayground',
				'video-aspect': type === 'video'
			}"
		>
			<img
				class="w-full h-full object-cover rounded-lg"
				[ngSrc]="image()"
				alt="Card Image"
				fill
			/>
			<ng-content select="[imageOverlay]"></ng-content>
		</div>

		<div
			class="content flex-grow flex flex-col py-1"
			[ngClass]="{
				'w-full': type === 'player',
				'justify-center lg:justify-start': true
			}"
		>
			<ng-content select="[preContent]"> </ng-content>
			<!-- Title -->
			<span class="title-wrapper block">
				<!-- Title -->
				<p
					[ngClass]="{
						'academyplayground    text-yellow-300 break-all ':
							type === 'academyandplayground',
						' text-white capitalize text-lg font-bold block break-all':
							type !== 'academyandplayground'
					}"
					class="title text-wrap"
				>
					{{ title() }}
				</p>
			</span>

			<span class="post-content text-xs">
				<ng-content select="[postContent]"> </ng-content>
			</span>
		</div>
	</div>
</div>

<ng-template #itemData let-primeIcon="primeIcon" let-value="value">
	<span class="flex items-center">
		<!-- Icon -->
		<i
			*ngIf="primeIcon"
			class="pi text-yellow-300"
			style="font-size: 16px"
			[ngClass]="primeIcon ? primeIcon : ''"
		></i>
		<!-- Data -->
		<span class="item-data-description px-2 text-white">{{ value }}</span>
	</span>
</ng-template>
