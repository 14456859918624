import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'popUp-success-pop-up',
  templateUrl: './success-pop-up.component.html',
  styleUrl: './success-pop-up.component.scss',
  standalone:true,
  imports:[CommonModule]
})
export class SuccessPopUpComponent {
  @Input() message: string;
  @Input() displayToast: boolean;
}
