import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable( {
	providedIn: 'root'
} )
export class ProfileLockedService {

	constructor(private http: HttpClient) { }

	makePlayersUnlock(playerId: number) {
		return this.http.post(`${environment.baseUrl}players/${playerId}/unlock`, {})
	}


}
