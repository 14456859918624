@if (calenderState) {
<div class="flex w-full  rounded-lg p-2 overflow-auto">
	<table class="w-full">
		@if (navigationControllerState) {
		<tr class="bg-darkBlue rounded-lg header">
			<td class="p-3"></td>
			<td class="p-3">
				<button (click)="navigateToPreviousWeek()" *ngIf="dateNow > todayDate"
					class="w-full border-0 font-body capitalize text-textYellow text-lg flex flex-row align-middle justify-start gap-2">
					<svg class="w-6 h-6 rtl:rotate-180" data-slot="icon" fill="none" stroke-width="1.5"
						stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
						<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5"></path>
					</svg>
					{{ dateNow|date:'d MMM' }}
				</button>
			</td>
			<td class="p-3 text-center" colspan="5">
				<p class="text-fontColor font-bold text-lg">{{ dateNow|date:'MMMM' }}</p>
			</td>
			<td class="p-3">
				<button (click)="navigateToNextWeek()"
					class="w-full border-0 font-bold capitalize text-textYellow text-lg flex flex-row align-middle justify-end gap-2">
					{{ week |date:'d MMM'}}
					<svg class="w-6 h-6 rtl:rotate-180" data-slot="icon" fill="none" stroke-width="1.5"
						stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
						<path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"></path>
					</svg>
				</button>
			</td>
		</tr>
		}
		<tr class=" rounded-lg week">
			<td class="text-fontColor p-3 text-center font-bold text-lg"></td>
			@for (day of days; track $index) {
			<td class="text-fontColor p-3 text-center font-bold text-lg">{{ getDay(day) |date:'E, dd/MM'}}</td>
			}
		</tr>
		@if (typeOfCalender === 'playground') {
		<ng-container *ngTemplateOutlet="mainCalenderContent; context:{items:playgroundBooking}"></ng-container>
		} @else {
		<ng-container *ngTemplateOutlet="mainCalenderContent; context:{items:academyBooking}"></ng-container>
		}
	</table>
</div>
}
<ng-template #mainCalenderContent let-items="items">
	<tr *ngFor="let hour of items; let hourIndex = index">
		<td class="p-4 bg-darkBlue text-center">
			<p class="text-textYellow font-bold text-base">{{ hour }}</p>
		</td>
		<td *ngFor="let day of days; let dayIndex = index" class="relative">
			<i *ngIf="role === 'admin'"
				class="material-icons absolute top-0 right-0 text-white opacity-80 text-2xl z-30 cursor-pointer"
				(click)="toggleOptions(hourIndex, dayIndex)">{{ showOptions[hourIndex][dayIndex] ? 'keyboard_arrow_up'
				: 'keyboard_arrow_down' }}</i>
			<div class="flex justify-center items-center border border-darkBlue w-32 h-20" [ngClass]="{
				'bg-textGreen': ( (isAvailable({ day: day, hour: hour }) && role === 'admin')) || (isSelected({day: day, hour: hour}) && role === 'user'),
				'bg-primaryRed': !isAvailable({ day: day, hour: hour }),
				'bg-semiDarkBlue': isAvailable({ day: day, hour: hour }) && role === 'user',
				'cursor-pointer' : role === 'user',
			}" (click)="bookDate({day:day , hour:hour})">
				<svg *ngIf="!isAvailable({ day: day, hour: hour })" class="w-8 h-8 stroke-white opacity-40"
					data-slot="icon" fill="none" stroke-width="4" stroke="currentColor" viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
					<path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12"></path>
				</svg>
				<svg *ngIf=" role === 'admin' && isAvailable({ day: day, hour: hour }) ||  (isSelected({day: day, hour: hour}) && role === 'user')"
					class="w-8 h-8 stroke-white opacity-40" data-slot="icon" fill="none" stroke-width="4"
					stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
					<path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5"></path>
				</svg>
				<div class="__options h-full w-full absolute fade top-0 text-center bg-primaryBlack"
					*ngIf="role === 'admin' && showOptions[hourIndex][dayIndex]">
					<ul class="text-xs text-fontColor h-full text-center flex flex-col items-center justify-center">
						<li (click)="select({day:day , hour:hour}, hourIndex, 'available'); toggleOptions(hourIndex, dayIndex)"
							class="p-3 cursor-pointer w-full h-full hover:text-lightBlue transition-all border-t border-b border-transparent hover:border-t hover:border-b hover:border-lightBlue">
							Available</li>
						<li (click)="select({day:day , hour:hour}, hourIndex,'disable'); toggleOptions(hourIndex, dayIndex)"
							class="p-3 cursor-pointer w-full h-full hover:text-lightBlue transition-all border-t border-b border-transparent hover:border-t hover:border-b hover:border-lightBlue">
							Disable</li>
					</ul>
				</div>
			</div>
		</td>
	</tr>
</ng-template>
