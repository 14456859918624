import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
	selector: "app-advertisement-card",
	templateUrl: "./advertisement-card.component.html",
	styleUrl: "./advertisement-card.component.scss",
	standalone:true,
	imports:[CommonModule]
})
export class AdvertisementCardComponent {
	@Input() adImage: string;
	@Input() adLayout: string;
	@Input() customHeight: string;
	@Input() customWidth: string = "80%";

	getStyles(layout: string): any {
		return layout === "vertical"
			? {
					"background-image": `url('${this.adImage}')`,
					height: `${this.customHeight}`,
			  }
			: { "background-image": `url('${ this.adImage }')`, "width": this.customWidth, };
	}
}
