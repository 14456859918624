import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShortListResponse } from '@sportyano/core/models/short-list/short-list.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ShortlistService {

	constructor(private http: HttpClient) { }

	getPlayersShortlist(params: any): Observable<ShortListResponse> {
		return this.http.get<ShortListResponse>(`${environment.baseUrl}list-players/shortlist`, { params: params })
	}

	makePlayersShortlist(playerId: number) {
		return this.http.post(`${environment.baseUrl}players/${playerId}/shortlist`, {})
	}
}
