<section>
    @if (userHasPermission) {
    <div class="flex flex-row justify-between items-center flex-wrap gap-4 lg:gap-0">
        <div>
            <h3 class="text-3xl text-fontColor font-bold">
                {{ "account.account_management.tabs_items.Videos" | translate }}
            </h3>
            <small class="text-gray-500">{{ "account.account_management.news_Media.uploadedVideoNote" | translate }}</small>
        </div>
        <div class="flex flex-row justify-end items-center">
            <button (click)="deleteItems()" *ngIf="anyItemSelected()"
                class="ms-auto flex flex-row justify-center items-center w-40 h-10 bg-semiLightGray border border-fontColor text-base text-fontColor rounded-lg shadow-md hover:border hover:border-lightBlue hover:text-lightBlue change-icon">
                {{ "account.account_management.news_Media.Delete" | translate }}
            </button>
            <button [disabled]="numberOfUploadedVideo() == maximumUploadedVideo" [ngClass]="
                numberOfUploadedVideo() == maximumUploadedVideo ? 'bg-semiDarkGrey text-inputFieldBackground' : 'bg-lightBlue text-fontColor hover:border hover:border-lightBlue'"
                (click)="addNewPost()" class="ms-3 me-2 w-40 h-10 rounded-lg shadow-md">
                <span class="text-base">
                    <i class="pi pi-plus"></i>
                    {{ "account.account_management.news_Media.Add_New_Video" | translate }}
                </span>
                <small *ngIf="numberOfUploadedVideo()" class="text-inputFieldBackground">{{
                    "(" + numberOfUploadedVideo() + "/" + maximumUploadedVideo + ")" }}</small>
            </button>
        </div>
    </div>
    }

    <div class="w-full grid grid-cols-1 gap-10 mt-5 px-4">
        <div class="rounded-2xl w-full bg-darkBlue">
            <ng-container *ngIf="willAddItem; else showTabs">
                <div class="card flex justify-content-center">
                    <add-post-pop-up *ngIf="!visibleEditVideo && willAddItem" [visible]="willAddItem" (onCancel)="willAddItem = false"
                        (onConfirm)="onVideoUpload()" [isVideo]="true" [fileType]="fileType"></add-post-pop-up>
                </div>
            </ng-container>

            <ng-template #showTabs>
                <p-tabView styleClass="tabview-custom news-tabs-dark" [(activeIndex)]="activeIndex">
                    <p-tabPanel [header]="approvedVideosTitleTab" styleClass="header-tab">
                        <div class="mt-8 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 justify-between items-center">
                            <ng-container *ngIf="listOfVideos()?.length === 0">
                                <div class="flex flex-col justify-center items-center">
                                    <img class="video" src="assets/images/image/no-video.png" alt="No Image" />
                                    <p class="text-white">{{ "no_videos" | translate }}</p>
                                  </div>
                            </ng-container>
                            <ng-container *ngFor="let item of listOfVideos() | localPagination : paginationConfig; let i = index">
                                <div class="relative">
                                    <app-cart-video [data]="item" [isShowSelected]="userHasPermission"
                                        [(isSelected)]="item.isSelected" (deleteVideo)="deleteUserVideo($event)"
                                        (editVideo)="onEditVideo(item)" [canDelete]="true" />
                                </div>
                            </ng-container>
                        </div>
                        <paggination [ngClass]="{'disappear':listOfVideos().length===0}"
                            [rows]="paginationConfig.per_page" [totalRecords]="listOfVideos().length"
                            (pageChanged)="changePage($event)" />
                    </p-tabPanel>
                    <p-tabPanel [ngClass]="{'disappear':listOfVideosPending().length===0}" [header]="declinePendingVideosTitleTab" headerStyleClass="header">
                        <section>
                            <ng-container>
                                <div class="mt-8 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 justify-between items-center">
                                    <ng-container *ngIf="listOfVideosPending()?.length === 0">
                                        <div class="flex flex-col justify-center items-center">
                                            <img class="video" src="assets/images/image/no-video.png" alt="No Image" />
                                            <p class="text-white">{{ "no_videos" | translate }}</p>
                                          </div>
                                    </ng-container>
                                    <div class="relative" *ngFor="let item of listOfVideosPending() | localPagination : paginationConfig; let i = index">
                                        <app-cart-video [data]="item" [isShowSelected]="userHasPermission" 
                                            [(isSelected)]="item.isSelected" (deleteVideo)="deleteUserVideoPending($event)"
                                            (editVideo)="onEditVideo($event)" [canDelete]="true" />
                                        @if (item.status == 0) {
                                            <div class="__timer-icon absolute top-[65%] left-1/2 -translate-x-1/2 -translate-y-1/2">
                                                <svg fill="#ffffff" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M23,11a1,1,0,0,0-1,1,10.034,10.034,0,1,1-2.9-7.021A.862.862,0,0,1,19,5H16a1,1,0,0,0,0,2h3a3,3,0,0,0,3-3V1a1,1,0,0,0-2,0V3.065A11.994,11.994,0,1,0,24,12,1,1,0,0,0,23,11Z M12,6a1,1,0,0,0-1,1v5a1,1,0,0,0,.293.707l3,3a1,1,0,0,0,1.414-1.414L13,11.586V7A1,1,0,0,0,12,6Z" />
                                                </svg>
                                            </div>
                                        } @else if(item.status == 2) {
                                            <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20">
                                                <i class="pi pi-times text-red-600" style="font-size: 4rem"></i>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </ng-container>
                            <paggination [rows]="paginationConfig.per_page" [totalRecords]="listOfVideosPending().length" (pageChanged)="changePage($event)" />
                        </section>
                    </p-tabPanel>
                </p-tabView>
            </ng-template>
        </div>
    </div>
</section>

<p-confirmDialog #cd>
    <ng-template pTemplate="headless" let-message>
        <div class="flex flex-col items-center p-5 bg-white rounded-lg shadow-lg">
            <div class="rounded-full bg-darkYellow bg-primary flex justify-center items-center h-24 w-24">
                <i class="pi pi-question text-5xl"></i>
            </div>
            <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
            <p class="mb-0">{{ message.message }}</p>
            <div class="flex items-center gap-2 mt-4">
                <button pButton [label]="'confirmation.submit' | translate" (click)="cd.accept()"
                    class="w-32 bg-green-500 text-white py-2 px-4 rounded-lg"></button>
                <button pButton [label]="'confirmation.cancel' | translate" (click)="cd.reject()"
                    class="w-32 border bg-gray-200 text-semiDarkGrey text-primary py-2 px-4 rounded-lg"></button>
            </div>
        </div>
    </ng-template>
</p-confirmDialog>

<!-- Edit Video Popup -->
<div class="card flex justify-content-center">
    <add-post-pop-up *ngIf="visibleEditVideo" [visible]="visibleEditVideo" [isEdit]="true"
        (onCancel)="visibleEditVideo = false" (onConfirm)="fn($event)" (onVisible)="visibleEditVideo = false" [isVideo]="true"
        [videos]="_selectedVideo()"></add-post-pop-up>
</div>
