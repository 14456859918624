import { Component, EventEmitter, Output, OnInit } from "@angular/core";
import { GeneralService } from "@sportyano/core/services/generals/general.service";

@Component({
	selector: "app-head-advanced-filteration",
	templateUrl: "./head-advanced-filteration.component.html",
	styleUrl: "./head-advanced-filteration.component.scss",
})
export class HeadAdvancedFilterationComponent implements OnInit {

	rangeValues: number[] = [20, 80];
	sortBy: any[] | undefined;
	nationality: any[] | undefined;
	filter: any = {
		search: '',
		name_sort: '',
		sport_id: '',
		nationality: '',
	}
	@Output() exportFilter: EventEmitter<void> = new EventEmitter<void>();
	constructor(private generalService: GeneralService) { }

	ngOnInit(): void {
		this.sortBy = [
			{ id: 1, name: 'asc' },
			{ id: 2, name: 'desc' },
		];

		this.generalService.getCountries().subscribe((data) => {
			if (data) {
				this.nationality = data.records
				this.nationality.find(ele => {
					return ele.code == '20'
				})
			}
		})
	}

	sendFilter() {
		let filterData: any = {}
		filterData.search = this.filter.search
		filterData.name_sort = this.filter.name_sort.name
		filterData.nationality = this.filter.nationality.id
		filterData.age_from = this.rangeValues[0]
		filterData.age_to = this.rangeValues[1]
		this.exportFilter.emit(filterData)
	}

	reset() {
		let filterData: any = {}
		this.exportFilter.emit(filterData)
	}
}
