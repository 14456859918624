<section class="bg-semiLightGray min-w-1/5 min-h-2/5 p-10" *ngIf="displayToast">
    <div class="fixed inset-0 flex items-center justify-center z-50">
        <div class="absolute inset-0 bg-darkBlue opacity-50"></div> <!-- Overlay -->
        <div class="relative bg-semiLightGray p-6 rounded-lg shadow-lg d-flex " style="width: 800px !important; min-height: 500px !important">
            <svg class="absolute top-28 left-64" xmlns="http://www.w3.org/2000/svg" width="307.687" height="274.936" viewBox="0 0 307.687 274.936">
                <path id="Path_33231" data-name="Path 33231" d="M846.406,438.925c-15.342-15.68-47.6-18.56-62.561,0q-38.6,47.913-77.211,95.828-18.381,22.816-36.766,45.632c-4.349,5.4-9.527,13.5-15.173,21.019q-1.654-1.7-3.315-3.408l-24.953-25.644c-39.779-40.878-102.313,21.712-62.563,62.561,20.523,21.089,41.484,50.569,69.311,61.7,30.514,12.2,57.577-2.481,77.194-26.283,45.957-55.762,90.7-112.572,136.036-168.839C861.506,482.745,864.38,457.3,846.406,438.925Z" transform="translate(-551.145 -426.062)" fill="#253141"/>
              </svg>
              <div class="absolute top-56 left-60 z-10 text-xl text-textGreen font-bold text-center">
                {{message}}
            </div>
        </div>
    </div>
</section>