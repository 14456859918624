<div class="rounded-md flex flex-col gap-4">
	<div class="flex flex-col sm:flex-row gap-8">
		<div class="flex flex-col justify-start gap-3 w-full lg:w-1/3">
			<label for="selectedSortBy" class="text-textYellow font-bold text-base">
				{{ "account.branches_management.player_list.sort_by" | translate }}
			</label>
			<p-dropdown id="selectedSortBy" [(ngModel)]="filter.name_sort" [options]="sortBy" optionLabel="name"
				placeholder="{{ 'account.branches_management.player_list.sort_by' | translate }}">
			</p-dropdown>
		</div>

		<div class="flex flex-col justify-start gap-3 w-full lg:w-1/3" *ngIf="false">
			<label for="selectedPosition" class="text-textYellow font-bold text-base">
				{{ "account.branches_management.player_list.player_position" | translate }}
			</label>
			<!-- <p-dropdown id="selectedPosition" [(ngModel)]="selectedPosition" [options]="playerPosition" -->
			<p-dropdown id="selectedPosition" optionLabel="name"
				placeholder="{{ 'account.branches_management.player_list.player_position' | translate }}">
			</p-dropdown>
		</div>

		<div class="flex flex-col justify-start gap-3 w-full lg:w-1/3">
			<label for="selectedNationality" class="text-textYellow font-bold text-base">
				{{ "account.branches_management.player_list.nationality" | translate }}
			</label>
			<p-dropdown id="selectedNationality" [(ngModel)]="filter.nationality" [options]="nationality"
				optionLabel="name"
				placeholder="{{ 'account.branches_management.player_list.nationality' | translate }}">
			</p-dropdown>
		</div>
		<div class="flex flex-col justify-start gap-3 w-full lg:w-1/3">
			<label for="selectedNationality" class="text-textYellow font-bold text-base">
				{{ "account.branches_management.player_list.player_age" | translate }} {{ rangeValues[0] + " - " +
				rangeValues[1] }}
			</label>
			<p-slider [(ngModel)]="rangeValues" [range]="true"></p-slider>
		</div>

	</div>

	<div class="flex flex-col sm:flex-row gap-4">
		<div class="flex flex-row relative w-full lg:w-3/5">
			<input type="search" dir="auto" class="border border-fontColor text-base rounded-md text-fontColor placeholder-fontColor py-3 w-full bg-darkBlue focus:outline-none focus:shadow-none focus:border-fontColor
					ltr:pr-14 rtl:pl-14" [(ngModel)]="filter.search"
				placeholder="{{ 'account.branches_management.player_list.search' | translate }}">
			<svg data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24"
				xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
				class="w-6 h-6 absolute top-3 end-5 stroke-fontColor">
				<path stroke-linecap="round" stroke-linejoin="round"
					d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"></path>
			</svg>
		</div>

		<div class="flex flex-row relative w-full lg:w-1/5">
			<button (click)="sendFilter()"
				class="w-full capitalize border border-primaryRed bg-primaryRed hover:bg-transparent px-4 py-2 rounded text-fontColor hover:text-primaryRed transition-colors">{{'search'
				| translate}}</button>

		</div>
		<div class="flex flex-row relative">
			<button (click)="reset()"
				class="w-full capitalize border border-primaryRed bg-primaryRed hover:bg-transparent px-4 py-2 rounded text-fontColor hover:text-primaryRed transition-colors"><svg
					height="25" viewBox="0 0 512 512" width="25" xmlns="http://www.w3.org/2000/svg">
					<path fill="white"
						d="m320 170.666667c28.289759 0 55.420843 11.238063 75.424723 31.241943 20.003881 20.00388 31.241944 47.134964 31.241944 75.424723 0 58.910374-47.756294 106.666667-106.666667 106.666667s-106.666667-47.756293-106.666667-106.666667c0-58.910373 47.756294-106.666666 106.666667-106.666666zm0 21.333333c-47.128299 0-85.333333 38.205035-85.333333 85.333333 0 47.128299 38.205034 85.333334 85.333333 85.333334s85.333333-38.205035 85.333333-85.333334c0-47.128298-38.205034-85.333333-85.333333-85.333333zm36.543147 33.705237 15.084949 15.08495-36.544192 36.54305 36.544192 36.543243-15.084949 15.084949-36.543243-36.544192-36.543051 36.544192-15.084949-15.084949 36.543-36.543243-36.543-36.54305 15.084949-15.08495 36.543051 36.543zm-15.209814-225.705237-149.333333 181.999v122.001h-42.666667l-.000333-122-149.333-182zm-90.218666 42.6666667h-160.9173337l80.4693337 98.0480003z"
						fill-rule="evenodd" transform="translate(42.666667 85.333333)" />
				</svg></button>
		</div>
	</div>

</div>