import { DatePipe, Time, isPlatformBrowser } from "@angular/common";
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnInit,
	Output,
	PLATFORM_ID,
	SimpleChanges,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";
import {
	permission,
	UserType,
} from "@sportyano/core/models/permissions/permission";
import { TimeSlot } from "@sportyano/core/models/programs/time-slots.model";
import { ProgramsScheduleService } from "@sportyano/core/services/academy/programs-schedule/programs.schedule.service";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import { ToasterMessagesService } from "@sportyano/core/services/toaster-messages/toaster-messages.service";
import {
	SLOT_STATUS,
	SlotStatusType,
} from "@sportyano/shared/components/custom-calendar/models/const/playground-status";
import { IPlaygroundReservedResponse } from "@sportyano/shared/components/custom-calendar/models/const/reserved-response";
import { tap } from "rxjs";

@Component({
	selector: "app-calendar",
	templateUrl: "./custom-calendar.component.html",
	styleUrl: "./custom-calendar.component.scss",
	providers: [DatePipe],
})
export class CustomCalendarComponent implements OnInit, OnChanges {
	// INPUTS
	@Input() role: "admin" | "user";
	@Input() typeOfCalender: permission.playground | permission.academy =
		permission.academy;
	@Input() navigationControllerState: boolean = true;
	userType: UserType;
	@Input() playgroundBooking: string[] = [
		"9AM - 10AM",
		"10AM - 11AM",
		"11AM - 12PM",
		"12PM - 1PM",
		"1PM - 2PM",
		"2PM - 3PM",
		"3PM - 4PM",
		"4PM - 5PM",
		"5PM - 6PM",
		"6PM - 7PM",
		"7PM - 8PM",
		"8PM - 9PM",
		"9PM - 10PM",
		"10PM - 11PM",
		"11PM - 12AM",
	];
	@Input() daysOff: number[] = [];
	@Input() academyBooking: string[] = [];
	// OUTPUTS
	@Output() emitCurrentChosenDates: EventEmitter<any[]> = new EventEmitter<
		any[]
	>();
	// PRIVATE
	private _reservedPlayground: Map<Date, number> = new Map<Date, number>();
	// PUBLIC
	programId: string;
	currentAvailableSlots: TimeSlot[] = [];
	currentReservedPlaygroundSlots: Date[] = [];
	chosenSuitableDates: any[] = [];
	days: number[];
	bookingDays: string[] = [
		"Saturday",
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
	];
	dateNow: Date = new Date();
	todayDate: Date = new Date();
	week: Date = new Date();
	selectedHours: CalendarItem[] = [];
	// FLAGS
	calenderState: boolean;
	showOptions: boolean[][] = [];

	constructor(
		@Inject(PLATFORM_ID) private platform: object,
		private toastr: ToasterMessagesService,
		private translate: TranslateService,
		private authService: AuthService,
		private datePipe: DatePipe,
		private programSchedule: ProgramsScheduleService,
		private activatedRoute: ActivatedRoute
	) {
		this.days = this.weekDays([0, 1, 2, 3, 4, 5, 6]);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes["daysOff"]?.currentValue.length > 0) {
			let daysOff = changes["daysOff"].currentValue;
			this.days = this.days.filter((day) => !daysOff.includes(day));
		}
	}

	private weekDays(days: number[]): number[] {
		const today = this.todayDate.getDay();
		const todayIndex = days.indexOf(today);
		const daysBeforeToday = days.slice(0, todayIndex);
		const daysAfterToday = days.slice(todayIndex);

		return daysAfterToday.concat(daysBeforeToday);
	}

	ngOnInit(): void {
		if (this.playgroundBooking.length === 0) {
			this.playgroundBooking = [
				"9AM - 10AM",
				"10AM - 11AM",
				"11AM - 12PM",
				"12PM - 1PM",
				"1PM - 2PM",
				"2PM - 3PM",
				"3PM - 4PM",
				"4PM - 5PM",
				"5PM - 6PM",
				"6PM - 7PM",
				"7PM - 8PM",
				"8PM - 9PM",
				"9PM - 10PM",
				"10PM - 11PM",
				"11PM - 12AM",
			];
		}

		this.programId =
			this.activatedRoute.snapshot.params["programId"] ||
			this.activatedRoute.snapshot.params["playgroundId"] ||
			this.activatedRoute.snapshot.params["id"];
		this.userType = this.authService.getUserType();
		this.getAllTimeSlots();
		this.week.setDate(this.dateNow.getDate() + 7);
		if (this.role === "admin") {
			let length =
				this.userType === permission.academy
					? 3
					: this.playgroundBooking?.length;

			for (let i = 0; i < length; i++) {
				this.showOptions[i] = [];
				for (let j = 0; j < 7; j++) {
					this.showOptions[i][j] = false;
				}
			}
		}
	}
	getAllTimeSlots(startDate?: Date, endDate?: Date) {
		if (this.typeOfCalender === permission.academy) {
			this.programSchedule
				.getTimeSlots(this.programId, permission.academy)
				.subscribe({
					next: (res) => {
						this.currentAvailableSlots = res.slots.filter(
							(ele) => ele.status !== 0
						);
						this.calenderState = true;
					},
				});
		} else {
			const currentDate = new Date();
			const nextWeek = new Date(
				currentDate.getTime() + 7 * 24 * 60 * 60 * 1000
			);
			const formattedCurrentDate = currentDate
				.toISOString()
				.split("T")[0];
			const formattedNextWeekDate = nextWeek.toISOString().split("T")[0];
			const formattedStartDate = startDate
				? startDate.toISOString().split("T")[0]
				: formattedCurrentDate;
			const formattedEndDate = endDate
				? endDate.toISOString().split("T")[0]
				: formattedNextWeekDate;

			// this.programSchedule
			// 	.getReservedPlaygroundDates(
			// 		this.programId,
			// 		formattedStartDate,
			// 		formattedEndDate
			// 	)
			// 	.pipe(
			// 		tap((t) => {
			// 			this._reservedPlayground.clear();
			// 		})
			// 	)
			// 	.subscribe({
			// 		next: (res: Array<IPlaygroundReservedResponse>) => {
			// 			res.forEach((slot) => {
			// 				this._reservedPlayground.set(
			// 					slot.time_slot,
			// 					slot.id
			// 				);
			// 			});
			// 			this.currentReservedPlaygroundSlots = [
			// 				...this._reservedPlayground.keys(),
			// 			];

			// 			this.calenderState = true;
			// 		},
			// 	});
		}
	}

	bookDate(item: CalendarItem) {
		if (this.role === "admin") {
			return;
		} else {
			if (this.typeOfCalender === permission.academy) {
				const selectedDate = this.getDay(item.day);
				const selectedDayName = this.getDayName(selectedDate);
				const selectedHour = this.convertTimeSlotToHour(item.hour);
				const matchingSlot = this.currentAvailableSlots.find((slot) => {
					const slotDate = new Date(slot.slot);
					const slotDayName = this.getDayName(slotDate);
					const slotHour = parseInt(
						this.datePipe.transform(slotDate, "HH")!
					);
					return (
						slotDayName === selectedDayName &&
						slotHour === selectedHour
					);
				});

				if (matchingSlot) {
					const isExistedItem = this.chosenSuitableDates.findIndex(
						(ele) => ele.id === matchingSlot.id
					);
					if (isExistedItem === -1) {
						let isSameDay = this.chosenSuitableDates.findIndex(
							(searchItem) =>
								searchItem.day === this.bookingDays[item.day]
						);
						if (isSameDay !== -1) {
							this.toastr.showError(
								this.translate.instant("booking_same_day")
							);
							return;
						} else {
							this.chosenSuitableDates.push({
								id: matchingSlot.id,
								day: this.bookingDays[item.day],
								hour: item.hour,
							});
						}
					} else {
						this.chosenSuitableDates.splice(isExistedItem, 1);
					}
				} else {
					this.toastr.showWarning(
						this.translate.instant("date_not_available")
					);
				}
				const index = this.selectedHours.findIndex(
					(x) => x.day === item.day && x.hour === item.hour
				);
				if (index !== -1) {
					this.selectedHours.splice(index, 1);
				} else {
					this.selectedHours.push(item);
				}
				this.emitCurrentChosenDates.emit(this.chosenSuitableDates);
			} else {
				const selectedTimestamp = this.getSelectedTimestamp(item);
				if (this.isTimestampReserved(selectedTimestamp)) {
					this.toastr.showWarning(
						this.translate.instant("date_not_available")
					);
					return;
				}

				const isExistedItem = this.chosenSuitableDates.findIndex(
					(ele) =>
						ele.day === this.bookingDays[item.day] &&
						ele.hour === item.hour
				);
				if (isExistedItem === -1) {
					this.chosenSuitableDates.push({
						day: this.bookingDays[item.day],
						hour: item.hour,
					});
				} else {
					this.chosenSuitableDates.splice(isExistedItem, 1);
				}
				const timestampArray =
					this.convertChosenSuitableDatesToTimestamps(
						this.chosenSuitableDates
					);
				this.emitCurrentChosenDates.emit(timestampArray);
			}
		}
	}

	private getSelectedTimestamp(item: CalendarItem): string {
		const selectedDate = this.getDay(item.day);
		const selectedHour = this.convertTimeSlotToHour(item.hour);
		selectedDate.setHours(selectedHour);
		return this.datePipe.transform(selectedDate, "yyyy-MM-dd HH:mm:ss")!;
	}

	private isTimestampReserved(selectedTimestamp: string): boolean {
		const unavailableTimestamps = this.currentReservedPlaygroundSlots.map(
			(date) => {
				return this.datePipe.transform(
					new Date(date),
					"yyyy-MM-dd HH:mm:ss"
				)!;
			}
		);

		const selectedDate = new Date(selectedTimestamp);
		const selectedDayName = this.getDayName(selectedDate);
		const selectedHour = selectedDate.getHours();

		return unavailableTimestamps.some((slot) => {
			const slotDate = new Date(slot);
			const slotDayName = this.getDayName(slotDate);
			return (
				slotDayName === selectedDayName &&
				slotDate.getHours() === selectedHour
			);
		});
	}

	toggleOptions(hourIndex: number, dayIndex: number) {
		this.showOptions[hourIndex][dayIndex] =
			!this.showOptions[hourIndex][dayIndex];
	}

	navigateToNextWeek() {
		this.dateNow = new Date(
			this.dateNow.setDate(this.dateNow.getDate() + 7)
		);
		this.week = new Date(this.week.setDate(this.week.getDate() + 7));
		this.getAllTimeSlots(
			this.dateNow,
			new Date(this.dateNow.getTime() + 7 * 24 * 60 * 60 * 1000)
		);
	}

	navigateToPreviousWeek() {
		this.dateNow = new Date(
			this.dateNow.setDate(this.dateNow.getDate() - 7)
		);
		this.week = new Date(this.week.setDate(this.week.getDate() - 7));
		this.getAllTimeSlots(
			this.dateNow,
			new Date(this.dateNow.getTime() + 7 * 24 * 60 * 60 * 1000)
		);
	}

	getDay(weekDay: number): Date {
		const date = new Date(this.dateNow);
		var distance = (weekDay + 7 - date.getDay()) % 7;

		date.setDate(date.getDate() + distance);
		return date;
	}

	select(
		item: CalendarItem,
		hourIndex: number,
		status: SlotStatusType
	): void {
		const { matchingSlot, formattedDate } =
			this._generateTimeSlotData(item);

		console.log("STATUS", status);
		console.log("matchingSlot", matchingSlot);

		if (matchingSlot && status === SLOT_STATUS.available) {
			const id = matchingSlot.id;
			this.programSchedule
				.deleteProgramTimeSlot(this.programId, id)
				.subscribe({
					next: (res) => {
						this.getAllTimeSlots();
					},
				});


			
			return;
		}
		// else if (matchingSlot && status === "available") {
		// 	this.toastr.showWarning("Timeslot is already on.");
		// 	return;
		// }
		// else {
		const dataToSend = {
			slot: formattedDate,
			duration: this.userType === permission.academy ? "3" : "1",
			day_status:
				hourIndex === 0
					? "morning"
					: hourIndex === 1
					? "afternoon"
					: "night",
			status: status == SLOT_STATUS.disable ? true : false,
		};
		const timeSlotId =
			this._reservedPlayground.get(formattedDate as unknown as Date) 

		this.programSchedule
			.createTimeSlot(
				this.programId,
				dataToSend,
				this.userType,
				timeSlotId as number
			)
			.subscribe({
				next: (res) => {
					this.getAllTimeSlots();
				},
			});
		// }
	}

	private _generateTimeSlotData(item: CalendarItem): {
		matchingSlot: TimeSlot | undefined;
		formattedDate: string | null;
	} {
		const selectedDate = this.getDay(item.day);
		const mainDate = this.getDay(item.day);
		let selectedHour: number;
		let hour = item.hour.split(" - ")[0];
		if (hour.includes("PM") && !hour.includes("12")) {
			hour = hour.replace("PM", "");
			selectedHour = parseInt(hour) + 12;
		} else {
			hour = hour.replace("AM", "");
			selectedHour = parseInt(hour);
		}
		mainDate.setHours(selectedHour);
		mainDate.setMinutes(0);
		mainDate.setSeconds(0);
		const formattedDate = this.datePipe.transform(
			mainDate,
			"yyyy-MM-dd HH:mm:ss"
		);

		const selectedDayName = this.getDayName(selectedDate);
		const matchingSlot = this.currentAvailableSlots.find((slot) => {
			const slotDate = new Date(slot.slot);
			const slotDayName = this.getDayName(slotDate);
			const slotHour = parseInt(this.datePipe.transform(slotDate, "HH")!);
			return slotDayName === selectedDayName && slotHour === selectedHour;
		});

		return {
			matchingSlot,
			formattedDate,
		};
	}

	isSelected(item: CalendarItem): boolean {
		if (this.typeOfCalender === permission.academy) {
			const selectedDate = this.getDay(item.day);
			const selectedDayName = this.getDayName(selectedDate);
			const selectedHour = this.convertTimeSlotToHour(item.hour);

			return (
				this.currentAvailableSlots.some((slot) => {
					const slotDate = new Date(slot.slot);
					const slotDayName = this.getDayName(slotDate);
					const slotHour = parseInt(
						this.datePipe.transform(slotDate, "HH")!
					);
					return (
						slotDayName === selectedDayName &&
						slotHour === selectedHour
					);
				}) &&
				this.chosenSuitableDates.some(
					(slot) =>
						slot.hour === item.hour &&
						slot.day === this.bookingDays[item.day]
				)
			);
		} else {
			return this.chosenSuitableDates.some(
				(slot) =>
					slot.hour === item.hour &&
					slot.day === this.bookingDays[item.day]
			);
		}
	}

	isAvailable(item: CalendarItem): boolean {
		if (this.typeOfCalender === permission.academy) {
			const selectedDate = this.getDay(item.day);
			const selectedDayName = this.getDayName(selectedDate);
			const selectedHour = this.convertTimeSlotToHour(item.hour);

			const isSlotAvailable = this.currentAvailableSlots.some((slot) =>
				this._isSlotMatching(slot, selectedDayName, selectedHour)
			);

			return !isSlotAvailable;
		} else {
			const unavailableTimestamps =
				this.currentReservedPlaygroundSlots.map((date) => {
					return new Date(date).getTime();
				});
			const selectedDay = this.getDay(item.day);
			const selectedHour = this.convertTimeSlotToHour(item.hour);
			selectedDay.setHours(selectedHour);
			const selectedTimestamp = selectedDay.getTime();
			return !unavailableTimestamps.some((slotTimestamp) => {
				const slotStart = slotTimestamp;
				const slotEnd = slotTimestamp + 60 * 60 * 1000;
				return (
					selectedTimestamp >= slotStart &&
					selectedTimestamp < slotEnd
				);
			});
		}
	}

	private _isSlotMatching(
		slot: any,
		selectedDayName: string,
		selectedHour: number
	): boolean {
		const slotDate = new Date(slot.slot);
		const slotDayName = this.getDayName(slotDate);
		const slotHour = parseInt(this.datePipe.transform(slotDate, "HH")!);
		return slotDayName === selectedDayName && slotHour === selectedHour;
	}
	private getDayName(date: Date): string {
		const dayNames = [
			"Sunday",
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
		];

		return dayNames[date.getDay()];
	}

	private convertTimeSlotToHour(timeSlot: string): number {
		if (this.typeOfCalender === permission.academy) {
			switch (timeSlot) {
				case this.academyBooking[0]:
					return 10;
				case this.academyBooking[1]:
					return 1;
				case this.academyBooking[2]:
					return 8;
				default:
					return 0;
			}
		} else {
			const startTime = timeSlot.split(" - ")[0];
			const isPM = startTime.includes("PM");
			const startHour = parseInt(startTime.split(":")[0]);
			if (startHour === 12 && !isPM) {
				return 0.5;
			} else if (startHour === 12 && isPM) {
				return 12.5;
			}
			let convertedHour = startHour + 0.5;
			if (isPM) {
				convertedHour += 12;
			}
			return convertedHour;
		}
	}

	convertChosenSuitableDatesToTimestamps(
		chosenSuitableDates: any[]
	): string[] {
		const timestampArray: string[] = [];
		chosenSuitableDates.forEach((date) => {
			const day = this.bookingDays.findIndex(
				(bookingDay) => bookingDay === date.day
			);
			const hour = this.convertTimeSlotToHour(date.hour);
			const selectedDate = this.getDay(day);
			selectedDate.setHours(hour);
			const timestampString = this.datePipe.transform(
				selectedDate,
				"yyyy-MM-dd HH:mm:ss"
			);
			if (timestampString) {
				timestampArray.push(timestampString);
			}
		});
		return timestampArray;
	}
}

interface CalendarItem {
	day: number;
	hour: string;
}

interface SlotGroup {
	[key: string]: TimeSlot[];
}

const slots: TimeSlot[] = [];
