<div class="flex flex-col gap-12 px-4 py-6">
	<ul class="flex flex-row gap-5 justify-end list-none">
		@for (item of subscription; track $index) {
		<li>
			<label for="item_{{ item.Id }}" class="btn-tab" [ngClass]="{ active: selectedSubscription == item.Id }">
				{{ item.Name }}
			</label>
			<input type="radio" name="plan-time" id="item_{{ item.Id }}" [(ngModel)]="selectedSubscription"
				value="{{ item.Id }}" class="hidden w-0 h-0" />
		</li>
		}
	</ul>

	<div class="relative my-86">
		<div class="overflow-x-auto py-10">
			<div class="plan-wrapper">
				@for (plan of subscriptionPlans; track plan.Id) {
				<label class="plan-item cursor-pointer" [ngClass]="{ selected: selectedPlan == plan.Id }"
					for="{{ plan.Id }}">
					<div class="plan-header">
						<label class="text-2xl cursor-pointer" for="{{ plan.Id }}">{{ plan.Name }}</label>
						<p class="text-white text-2xl">$ {{ plan.Price }} /mo</p>
					</div>

					<div class="plan-body">
						<ul class="flex flex-col gap-4">
							@for (item of plan.Items; track $index) {
							<li>
								<p class="text-sm text-fontColor text-center">
									{{ item }}
								</p>
							</li>
							}
						</ul>
						@if (selectedPlan == plan.Id && plan.Name !== 'Free plan')
						{ @if (plan.Subscribed) {
						<button
							class="transition-colors border border-red-600 text-red-600 hover:bg-red-600 hover:text-white rounded-md px-4 py-1 text-center">
							{{ "subscription.cancel_plan" | translate }}
						</button>
						} @else {
						<button (click)="subscribe(plan, plan.Id)"
							class="transition-colors border border-red-600 hover:text-red-600 bg-red-600 hover:bg-semiDarkBlue text-white rounded-md px-4 py-1 text-center">
							{{ "subscription.subscribe" | translate }}
						</button>
						} }

						@if (selectedPlan == plan.Id && plan.Name === 'Free plan' && !hiddenInAccount) {
						<button routerLink="/auth/login"
							class="transition-colors border border-red-600 text-red-600 hover:bg-red-600 hover:text-white rounded-md px-4 py-1 text-center">
							{{ "subscription.continue" | translate }}
						</button>
						}
					</div>

					<input type="radio" name="plan" [value]="plan.Id" id="{{ plan.Id }}" [(ngModel)]="selectedPlan"
						class="hidden w-0 h-0" />
				</label>
				}
			</div>
		</div>
	</div>
</div>