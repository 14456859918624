import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
	selector: "[appEnglishOnly]",
})
export class EnglishOnlyDirective {
	constructor(private el: ElementRef) {}

	@HostListener("input", ["$event"]) onInputChange(event: any) {
		const initialValue = this.el.nativeElement.value;
		const englishOnlyRegex = /^[a-zA-Z0-9\s]*$/;
		if (!englishOnlyRegex.test(initialValue)) {
			const newValue = initialValue.replace(/[^a-zA-Z0-9\s]/g, "");
			this.el.nativeElement.value = newValue;
			event.preventDefault();
		}
	}
}
