import {
	AfterViewInit,
	Directive,
	ElementRef,
	HostListener,
	inject,
	Renderer2,
} from "@angular/core";

@Directive({
	selector: "[disableVideoDownload]",
	standalone: true,
})
export class DisableVideoDownloadDirective implements AfterViewInit {
	private _elementRef: ElementRef = inject(ElementRef);
	private _renderer2: Renderer2 = inject(Renderer2);
	ngAfterViewInit() {
		const videoElement = this._elementRef.nativeElement;

    if (videoElement.tagName === "VIDEO") {
			this._renderer2.setAttribute(
				videoElement,
				"controlsList",
				"nodownload"
			);
		}
	}

	// Disable right-click on the element
	@HostListener("contextmenu", ["$event"])
	onRightClick(event: MouseEvent) {
		event.preventDefault();
	}
}
