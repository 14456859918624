import { Component, Input, OnInit } from "@angular/core";
import { UserType, permission } from "@sportyano/core/models/permissions/permission";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";

@Component({
	selector: 'app-news-card',
	templateUrl: './news-card.component.html',
	styleUrl: './news-card.component.scss'
})
export class NewsCardComponent implements OnInit {
	isMenuOpened: boolean = false;
	userType: UserType;
	@Input() showMenu: boolean = false;
	@Input() news: any;

	constructor(		private _authService:AuthService,
	) {
	}

	ngOnInit(): void {
			this.userType = this._authService.getUserType();
	}

	isPlayer(): boolean { return this.userType === permission.player }
}
