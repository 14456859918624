<div class="skills relative overflow-hidden rounded-lg text-white min-h-[40rem] h-full">
	<h3 class="cart-title absolute top-4 font-bold text-lg" [ngClass]="{
			'left-4': currentLanguage !== 'ar',
			'right-4': currentLanguage === 'ar'
		}">
		Skills Card
	</h3>
	<div class="photo w-full h-full rounded-lg">
		<img class="w-full h-full object-cover" src="assets/images/image/1.jpg" alt="" />
	</div>
	<div class="content bg-textGreen bottom-0 absolute w-full">
		<div class="absolute w-full h-56 bg-textGreen z-0 top-[-8%] right-[-4%] rotate-[-15deg] scale-125"></div>
		<div class="head flex justify-between font-black z-20 px-8 py-6 relative">
			<span class="w-1/2 text-lg">Mahmoud Trezegeaut</span>
			<span class="text-4xl">71</span>
		</div>
		<ul class="list-none flex justify-between flex-wrap z-20 px-8 py-6 relative">
			@for (user of playerSkills; track user.id) {
			<li class="skill mt-2 font-semibold">
				<p class="name uppercase text-inputFieldBackground">
					{{ user?.name }}
				</p>
				<span>{{ user?.value }}</span>
			</li>
			}
		</ul>
	</div>
</div>
