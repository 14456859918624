import { inject } from "@angular/core";
import { ToasterMessagesService } from "./../../../core/services/toaster-messages/toaster-messages.service";
import { SinglePlayerService } from "@sportyano/main-site/players/single-player.service";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import { Component, Input, OnDestroy } from "@angular/core";
import { ProfileLockedService } from "@sportyano/core/services/playerProfile/profileLocked.service";

@Component({
	selector: "unlock-profile-dialog",
	templateUrl: "./unlock-profile-dialog.component.html",
	styleUrl: "./unlock-profile-dialog.component.scss",
})
export class UnlockProfileDialogComponent implements OnDestroy {
	@Input() unlockProfileModel: boolean = false;
	authenticated: boolean = false;
	playerID: number = 1;

	private _toastrMessages = inject(ToasterMessagesService);
	constructor(
		private lockedService: ProfileLockedService,
		private authService: AuthService,
		private singlePlayerService: SinglePlayerService
	) {
		let userType = this.authService.getUserType();
		if (userType) this.authenticated = true;
		else this.authenticated = false;
		this.singlePlayerService.singlePlayer$.subscribe((data) => {
			this.playerID = data.id;
		});
	}

	onConfirmUnlock() {
		if (this.authenticated) {
			this.lockedService
				.makePlayersUnlock(this.playerID)
				.subscribe((data: any) => {
					this.unlockProfileModel = false;
					this._toastrMessages.showSuccess(data.message);
					location.reload();
				});
		} else this.authService.setLoginPopupState(true);
	}

	onCancelUnlock() {
		this.unlockProfileModel = false;
	}

	ngOnDestroy(): void {
		this.authService.setLoginPopupState(false);
	}
}
