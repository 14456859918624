import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShowInterestResponse } from '@sportyano/core/models/interest-list/interest-list.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ShowinterestService {

	constructor(private http: HttpClient) { }

	getPlayersInterests(params: any): Observable<ShowInterestResponse> {
		return this.http.get<ShowInterestResponse>(`${environment.baseUrl}list-players/interests`, { params: params })
	}

	makePlayersInterest(playerId: number) {
		return this.http.post(`${environment.baseUrl}players/${playerId}/show-interest`, {})
	}

	public likePlayer(playerId: number, likeState: boolean = true) {
		return this.http.post(`${environment.baseUrl}players/${playerId}/likes`, {})
	}
}
