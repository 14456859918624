import { Component } from '@angular/core';
import { LanguagesService } from '@sportyano/core/services/language services/languages.service';

@Component({
	selector: 'app-cart-skills',
	templateUrl: './cart-skills.component.html',
	styleUrl: './cart-skills.component.scss'
})
export class CartSkillsComponent {
	playerSkills: any[] = [
		{ name: 'Pace', value: 66 },
		{ name: 'drippling', value: 71 },
		{ name: 'shortting', value: 73 },
		{ name: 'deffence', value: 56 },
		{ name: 'Passing', value: 80 },
		{ name: 'physics', value: 52 }
	]
	currentLanguage: string = 'ar'

	constructor(private languagesService: LanguagesService) { }
	ngOnInit(): void {
		this.languagesService.language$.subscribe({
			next: (language: string) => {
				this.currentLanguage = language;
			},
		});
	}
}
