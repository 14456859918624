<ul class="lg:gap-x-5 text-sm gap-y-3 lg:gap-y-0 lg:flex">
	@for (option of options; track $index) {
	<li
		(click)="handleOptionClick(option)"
		class="capitalize font-normal text-fontColor hover:text-lightBlue transition-all cursor-pointer"
	>
		{{ option | translate }}
	</li>
	}
</ul>
