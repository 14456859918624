<main class="bg-darkBlue min-h-screen min-w-screen bg-no-repeat">
	<div class="w-full md:w-4/5 mx-auto p-8">
		<div class="__logo" dir="ltr">
			<!-- <img src="assets/images/logo/Logo.png" alt="Main Logo" class="cursor-pointer" routerLink="/main" /> -->
			<img [src]="
					translateService.currentLang === 'en'
						? 'assets/images/logo/sportyano-logo-en.png'
						: 'assets/images/logo/sportyano-logo-ar.png'
				" class="cursor-pointer w-52 mt-5 mb-7" [ngClass]="
					translateService.currentLang === 'en'
						? 'mr-auto'
						: 'ml-auto'
				" alt="main logo" routerLink="/main" />
			<small class="beta-version ml-4">{{
				"betaVersion" | translate
				}}</small>
		</div>
		<section class="md:flex">
			<div class="w-full md:w-2/5">
				<div class="__main-form pt-8">
					<header class="__header">
						<h1 class="uppercase text-start font-semibold text-fontColor lg:text-4xl text-2xl pb-3">
							{{ "authentication.login.form.login" | translate }}
						</h1>
					</header>

					<!-- FORM START -->

					<form [formGroup]="loginForm">
						<div class="__username">
							<label for="username" class="capitalize text-textYellow text-xs block pb-3">
								{{
								"authentication.login.form.username_email"
								| translate
								}}
							</label>
							<input required maxlength="11" [appCheckLanguage]="'numbers'" [dir]="
									translateService.currentLang === 'en'
										? 'ltr'
										: 'rtl'
								" id="username" class="w-full py-3 px-4 border-none bg-inputFieldBackground rounded-md placeholder-fontColor text-fontColor text-xs shadow-md"
								placeholder="{{
									'authentication.login.form.username_placeholder'
										| translate
								}}" required formControlName="username" />
							<div *ngIf="
									loginForm.get('username')?.invalid &&
									(loginForm.get('username')?.dirty ||
										loginForm.get('username')?.touched)
								">
								<!-- <small
									class="text-primaryRed text-md mt-2"
									*ngIf="loginForm.get('username')?.errors?.['invalidPhone']"
								>
									{{
										"authentication.errors.phoneNumber"
											| translate
									}}
								</small> -->
								<small class="text-primaryRed text-md mt-2"
									*ngIf="loginForm.get('username')?.hasError(CustomValidationType.required)">
									{{
									"authentication.errors.required"
									| translate
									}}
								</small>
								<small class="text-primaryRed text-md mt-2" *ngIf="loginForm.get('username')?.hasError(CustomValidationType.mobileNumberValidation) &&
									   !loginForm.get('username')?.hasError(CustomValidationType.required)">
									{{
									'authentication.errors.egyptian_mobile_number' | translate
									}}
								</small>
								<!-- <small
									class="text-primaryRed text-md mt-2"
									*ngIf="loginForm.get('username')?.errors?.['invalidLength']"
								>
									{{
										"authentication.errors.invalidLength"
											| translate
									}}
								</small> -->
							</div>
						</div>

						<div class="__password relative pt-4">
							<label for="password" class="capitalize text-textYellow text-xs block pb-3">
								{{
								"authentication.login.form.password"
								| translate
								}}
							</label>
							<div class="relative w-full">
								<input appPreventCopyCutPaste [preventCopy]="true" [preventCut]="true"
									[preventPaste]="false" required minlength="6" maxlength="15" [type]="
										passwordVisibility ? 'text' : 'password'
									" id="password" class="w-full py-3 px-4 border-none bg-inputFieldBackground rounded-md placeholder-fontColor text-fontColor text-xs shadow-md pr-8"
									placeholder="{{
										'authentication.login.form.password_placeholder'
											| translate
									}}" required formControlName="password" />
								<div class="absolute inset-y-0 ltr:right-4 rtl:left-4 flex items-center pr-3">
									<mat-icon class="cursor-pointer text-fontColor" (click)="
											passwordVisibility =
												!passwordVisibility
										">
										{{
										passwordVisibility
										? "visibility_off"
										: "visibility"
										}}
									</mat-icon>
								</div>
							</div>
							<div *ngIf="
								loginForm.get('password')?.invalid &&
								(loginForm.get('password')?.dirty ||
									loginForm.get('password')?.touched)
							">
								<small class="text-primaryRed text-md mt-2"
									*ngIf="loginForm.get('password')?.hasError(CustomValidationType.required)">
									{{
									"authentication.errors.required"
									| translate
									}}
								</small>
								<small class="text-primaryRed text-md mt-2" *ngIf="loginForm.get('password')?.hasError(CustomValidationType.patternValidator) &&
								!loginForm.get('password')?.hasError(CustomValidationType.required)">
									{{
									"authentication.errors.password_min_max_length"
									| translate
									}}
								</small>
							</div>

						</div>

						<div class="__submit mt-6">
							<button type="submit"
								class="border border-solid py-2 rounded-lg w-full bg-primaryRed capitalize text-fontColor hover:bg-darkBlue hover:text-primaryRed transition-all border-primaryRed"
								(click)="signIn()">
								{{
								"authentication.login.form.login"
								| translate
								}}
							</button>
						</div>

						<div class="__forgot-password">
							<a class="capitalize text-fontColor text-sm block mt-2 cursor-pointer hover:text-textYellow transition-all"
								routerLink="/auth/forgot-password">
								{{
								"authentication.login.form.forgot_password"
								| translate
								}}
							</a>
							<a [routerLink]="'/auth/register'"
								class="capitalize text-fontColor important-underline text-sm block mt-2 cursor-pointer hover:text-textYellow transition-all">
								{{
								"authentication.login.form.dont_have_account"
								| translate
								}}
							</a>
						</div>

						<!-- <div class="__social-media text-center mt-5">
							<button
								type="button"
								(click)="signInWithGoogle()"
								class="__google relative border py-3 rounded-lg w-full hover:bg-inputFieldBackground transition-all hover:border-inputFieldBackground"
							>
								<h3
									class="text-fontColor capitalize text-sm font-normal"
								>
									{{
										"authentication.login.social_media.continue_with_google"
											| translate
									}}
								</h3>
								<img
									class="absolute left-5 top-1"
									src="assets/icons/google.svg"
									alt=""
								/>
							</button>
						</div> -->
					</form>

					<!-- FORM END -->
				</div>
			</div>
			<div class="w-full md:w-3/4"></div>
		</section>
	</div>
</main>

<!-- Google Authentication OTP Verify -->
<form [formGroup]="socialMobileRegisterForm" *ngIf="phoneNumberDialogvisible">
	<div class="overlay fixed w-screen h-screen bg-black bg-opacity-60 top-0 z-50 transition-all">
		<div class="container mx-auto fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50">
			<div class="w-full bg-semiDarkBlue p-8 rounded-md lg:w-4/5 mx-auto">
				<!-- Close Icon SVG -->
				<div class="__close-icon flex justify-end">
					<svg height="30px" id="Layer_1" class="cursor-pointer" (click)="closeOtpDialog()"
						style="enable-background: new 0 0 512 512" version="1.1" viewBox="0 0 512 512" width="30px"
						xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink" fill="#ffffff">
						<path
							d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
					</svg>
				</div>

				<h3 class="text-white text-xl uppercase font-bold text-center my-5">
					{{ "authentication.otp.verify" | translate }}
				</h3>
				<div class="mobile md:w-2/6 mx-auto text-start mt-12 relative">
					<label for="mobile" class="capitalize text-textYellow text-xs block pb-3">
						{{
						"authentication.forget_password.mobile_no_label"
						| translate
						}}
						<span class="required">*</span>
					</label>
					<div>
						<i *ngIf="showTooltip('phone_number')"
							class="material-icons cursor-pointer text-red-800 absolute z-50 right-12 top-1/2"
							[matTooltip]="getErrorTooltip('phone_number')" matTooltipPosition="right">info_outline</i>
						<input appNumericOnly type="tel" maxlength="11" #phoneInput dir="ltr" id="mobile"
							formControlName="phone_number"
							class="w-full py-3 px-4 border-none bg-inputFieldBackground rounded-md placeholder-fontColor text-fontColor text-xs shadow-md"
							placeholder="{{
								'authentication.forget_password.mobile_no_label'
									| translate
							}}" />
						<div *ngIf="
								loginForm.get('phone_number')?.invalid &&
								(loginForm.get('phone_number')?.dirty ||
									loginForm.get('phone_number')?.touched)
							">
							<span class="text-primaryRed text-md mt-2"
								*ngIf="loginForm.get('phone_number')?.errors?.['invalidPhone']">
								{{
								"authentication.errors.phoneNumber"
								| translate
								}}
							</span>
							<span class="text-primaryRed text-md mt-2"
								*ngIf="loginForm.get('phone_number')?.errors?.['required']">
								{{
								"authentication.errors.required" | translate
								}}
							</span>
						</div>
					</div>
				</div>

				<div class="__password md:w-2/6 mx-auto relative pt-4">
					<label for="password" class="capitalize text-textYellow text-xs block pb-3">
						{{ "authentication.login.form.password" | translate }}
					</label>
					<div class="relative w-full">
						<input required [type]="passwordVisibility ? 'text' : 'password'" id="password"
							class="w-full py-3 px-4 border-none bg-inputFieldBackground rounded-md placeholder-fontColor text-fontColor text-xs shadow-md pr-8"
							placeholder="{{
								'authentication.login.form.password_placeholder'
									| translate
							}}" required formControlName="password" />
						<div class="absolute inset-y-0 ltr:right-4 rtl:left-4 flex items-center pr-3">
							<mat-icon class="cursor-pointer text-fontColor" (click)="
									passwordVisibility = !passwordVisibility
								">
								{{
								passwordVisibility
								? "visibility_off"
								: "visibility"
								}}
							</mat-icon>
						</div>
					</div>
				</div>

				<div class="__submit-btn text-center">
					<button type="submit" (click)="submitPhoneNumberVerify()"
						class="mt-12 mb-10 py-2 rounded-lg w-full md:w-2/6 outline-none bg-primaryRed capitalize text-fontColor hover:bg-primaryRed transition-all">
						{{
						"authentication.forget_password.send_button"
						| translate
						}}
					</button>
				</div>
			</div>
		</div>
	</div>
</form>