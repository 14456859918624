import { CommonModule, NgOptimizedImage } from "@angular/common";
import {
	Component,
	inject,
	Input,
	input,
	InputSignal,
	TemplateRef,
	ViewChild,
} from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ChipComponent } from "@sportyano/shared/components/chip/chip.component";
import { StopClickPropagationDirective } from "@sportyano/shared/directives/stop-click-propagation/stop-click-propagation.directive";
import { TextLimiterDirective } from "@sportyano/shared/directives/text-limiter/text-limiter.directive";

@Component({
	selector: "cart",
	standalone: true,
	imports: [
		CommonModule,
		RouterModule,
		TextLimiterDirective,
		TranslateModule,
		ChipComponent,
		StopClickPropagationDirective,
		NgOptimizedImage
	],
	templateUrl: "./cart.component.html",
	styleUrl: "./cart.component.scss",
})
export class CartComponent {
	// Inputs

	@Input() type :string = "image" || "video" || "academyandplayground";
	@Input() width:number;
	@Input() height:number;
	// Signals

	public image: InputSignal<string> = input.required();
	public title: InputSignal<string> = input.required();
	public imageWidth: InputSignal<number> = input(50);
	public route: InputSignal<string> = input("");
	public axis: InputSignal<"vertical" | "horizontal"> = input<
		"vertical" | "horizontal"
	>("horizontal");

	// Inject
	public _router = inject(Router);

	@ViewChild("itemData", { static: true })
	itemDataTemplate!: TemplateRef<any>;

	public navigateTo(route: string) {
		!!route && this._router.navigateByUrl(route);
	}
}
