export interface Account {
	id: number;
	name_ar: string;
	name_en: string;
	username: string;
	is_active: number;
	provider_id: string;
	provider: string;
	created_at: Date;
	updated_at: Date;
	address_en: string;
	address_ar: string;
}

export interface PlayerAccount {
	first_name_ar: string;
	first_name_en: string;
	first_name: string;
	last_name_ar: string;
	last_name_en: string;
	last_name: string;
	full_name: string;
	player_id: number;
	player_name_en: string | null;
	player_name_ar: string | null;
	player_name: string | null;
	nationality_name: string;
	nationality: number;
	birth_date: string;
	avatars: Avatar[];
	height: number;
	weight: number;
	country_name: string;
	country_id: number;
	city_id: number;
	city_name: string;
	academy_id: number | null;
	other_academy: string;
	sport_name: string;
	sport_id: number;
	sport_option_human_id: number;
	sport_option_human_label: string;
	property_id: number;
	property_name: string;
	description_ar: string;
	description_en: string;
	description: string;
	attachments: Attachment[];
}
export interface Avatar {
	avatar_id: number;
	url: string;
}

interface Attachment {
	attachment_id: number;
	url: string;
}

export interface PlayerAccountResponse {
	data: PlayerAccount;
}

export interface IProfileResponse {
	profile: IPlayerProfile;
	pending_profile: Partial<IPlayerProfile>;
}

export interface IPlayerProfile {
	id: number;
	first_name_ar: string;
	first_name_en: string;
	first_name: string;
	last_name_ar: string;
	last_name_en: string;
	last_name: string;
	full_name: string;
	slug: string;
	player_id: number;
	player_name_en: any;
	player_name_ar: any;
	player_name: any;
	nationality_name: string;
	nationality: number;
	gender: string;
	birth_date: string;
	avatars: Avatar[];
	height: number;
	weight: number;
	country_name: string;
	country_id: number;
	city_id: number;
	city_name: string;
	age: string;
	academy_id: any;
	academy_name: any;
	is_academy_verified: boolean;
	other_academy: string;
	sport_name: string;
	sport_id: number;
	sport_option_human_id: number;
	sport_option_human_label: string;
	property_id: number;
	property_name: string;
	sport_option_position_id: number;
	sport_option_position_name: string;
	description_ar: string;
	description_en: string;
	description: string;
	likes: number;
	attachments: any[];
	status: number;
	decline_reason: any;
}

export interface ViewerProfile {
	id: number;
	name_ar: string;
	name_en: string;
	sport_id: number;
	sport_name: string;
	birth_date: string;
}


export enum profileParams {
	players="player",
	academies="academy",
	playgrounds="playground",
	videos="video"
}


