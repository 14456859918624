import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FavoritesResponse } from "@sportyano/core/models/favourites/favourite.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class FavouriteListService {
	constructor(private httpClient: HttpClient) {}

	getFavouriteList(): Observable<FavoritesResponse> {
		return this.httpClient.get<FavoritesResponse>(
			environment.baseUrl + "favorite"
		);
	}

	toggleFavourite(
		id: number,
		typeOfFavourite: "player" | "academy" | "playground"
	): Observable<{ success: string; message: string }> {
		return this.httpClient.post<{ success: string; message: string }>(
			environment.baseUrl + "favorite",
			{
				favorite_id: id,
				favorite_type: typeOfFavourite,
			}
		);
	}
}
