import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "app-filteration-options",
	templateUrl: "./filteration-options.component.html",
	styleUrl: "./filteration-options.component.scss",
})
export class FilterationOptionsComponent {
	@Input() options: string[] = [];
	@Output() optionClicked = new EventEmitter<string>();

	handleOptionClick(option: string) {
		this.optionClicked.emit(option);
	}
}
