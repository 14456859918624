<div class="Dialog" *ngIf="unlockProfileModel">
	<div class="fixed inset-0 flex items-center justify-center z-50">
		<div class="relative bg-[#D9D9D9] p-6 rounded-lg shadow-lg"
			style="width: 600px !important; min-height: 450px !important">

			<div class="flex flex-col justify-center gap-14 px-5">
				<p class="uppercase font-bold text-bgDarkGrey text-2xl text-center">
					{{ "player.unlock_profile" | translate }}
				</p>
				<p class="uppercase font-bold text-lightBlue text-2xl text-center">
					{{ "player.unlock_message" | translate }}
				</p>

				<div class="flex flex-row justify-between gap-4 w-full mx-auto mt-5">
					<button (click)="onConfirmUnlock()"
						class="group border border-primaryRed bg-primaryRed hover:bg-transparent text-fontColor hover:text-primaryRed flex justify-center flex-nowrap gap-3 px-3 py-2 rounded-lg w-2/4">
						<svg class="flex group-hover:hidden fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 448 512">
							<path
								d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
						</svg>
						<svg class="hidden group-hover:flex fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 448 512">
							<path
								d="M144 144c0-44.2 35.8-80 80-80c31.9 0 59.4 18.6 72.3 45.7c7.6 16 26.7 22.8 42.6 15.2s22.8-26.7 15.2-42.6C331 33.7 281.5 0 224 0C144.5 0 80 64.5 80 144v48H64c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V256c0-35.3-28.7-64-64-64H144V144z" />
						</svg>
						{{ "player.unlock_profile" | translate }}
					</button>
					<button (click)="onCancelUnlock()"
						class="group border border-lightBlue bg-transparent hover:bg-lightBlue text-lightBlue hover:text-fontColor flex justify-center flex-nowrap gap-3 px-3 py-2 rounded-lg w-2/4">
						{{ "player.cancel" | translate }}
					</button>
				</div>
			</div>

		</div>
	</div>
</div>
