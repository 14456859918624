import {
	inject,
	DestroyRef,
	ViewChild,
	ElementRef,
	OnChanges,
	SimpleChanges,
	AfterContentInit,
} from "@angular/core";
import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Component, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ShowinterestService } from "@sportyano/core/services/generals/showinterest.service";
import { LanguagesService } from "@sportyano/core/services/language services/languages.service";
import { Router } from "@angular/router";
import { MediaService } from "@sportyano/core/services/media/media.service";
import {
	IMediaVideo,
	IVideo,
} from "@sportyano/account/components/news-and-media/models/interfaces/video.interface";
import { CartComponent } from "@sportyano/shared/components/cart/cart.component";
import { DateTimeUtils } from "@sportyano/shared/utilis/date-time-utilits";

@Component({
	selector: "app-cart-video",
	templateUrl: "./cart-video.component.html",
	styleUrl: "./cart-video.component.scss",
})
export class CartVideoComponent implements OnChanges, AfterContentInit {
	// INPUTS
	@Input() isSelected: boolean = true;
	@Input() isEdit: boolean = false;
	@Input() isShowSelected: boolean = true;
	@Input() data: IVideo = {} as IVideo;
	@Input() mediaVideo: IMediaVideo;
	@Input() canDelete: boolean = false;
	// OUTPUTS
	@Output() deleteVideo: EventEmitter<number> = new EventEmitter<number>();
	@Output() editVideo: EventEmitter<IVideo> = new EventEmitter<IVideo>();
	@Output() isSelectedChange = new EventEmitter<boolean>();

	// ViewChild
	@ViewChild(CartComponent) cartComponent: CartComponent;

	isHeart: boolean = false;
	checked: boolean = false;
	visible: boolean = false;
	showShare: boolean = false;
	currentLanguage: string = "ar";
	urlMap: any;
	lat: any = "27.168117";
	lng: any = "31.175801500000006";
	public videoDuration: string;
	private destroyRef = inject(DestroyRef);
	private _mediaService = inject(MediaService);
	constructor(
		private languagesService: LanguagesService,
		private sanitizer: DomSanitizer,
		private showinterest: ShowinterestService,
		private router: Router
	) {}
	navigateToProfile(slug: string) {
		this.router.navigate([`/main/players/${slug}`]);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (
			changes["mediaVideo"]?.currentValue ||
			changes["data"]?.currentValue
		) {
		}
		if (
			changes["isEdit"]?.currentValue
		) {
		}
	}
	checkIfMainPath(): void {
		// console.log('currentUrl',currentUrl)
		// console.log('this.isMainPath',this.isMainPath)

	  }
	ngAfterContentInit(): void {
		this._loadVideoMetadata(
			(this.data?.url) as string
		);
		// console.log(this.data);
		
	}
	ngOnInit(): void {
		this.languagesService.language$.subscribe({
			next: (language: string) => {
				this.currentLanguage = language;

			},
		});
		this.checkIfMainPath()
		this.urlMap = this.sanitizer.bypassSecurityTrustResourceUrl(
			`https://www.youtube.com/embed?v=BrtFUeO83YI`
		);
		// `https://www.google.com/maps/d/embed?mid=1UWlEy4hrONmDXMyyI4RZSa-6SFBImuI&usp=${this.lat}%2C${this.lng}&z=8`
	}

	private _loadVideoMetadata(videoUrl: string) {
		// Create a video element
		const videoElement = document.createElement("video");

		// Set the source of the video element
		videoElement.src = videoUrl;

		// Add event listeners to handle metadata and errors
		videoElement.addEventListener("loadedmetadata", () => {
			this.videoDuration = DateTimeUtils.secondsToMinSec(
				videoElement.duration
			);
		});
	}

	onChildValueChange() {
		this.isSelected = !this.isSelected;
		this.isSelectedChange.emit(this.isSelected);
	}

	share(event: any) {
		event.stopPropagation();
		this.showShare = true;
	}

	toggelHeart(e: any) {
		e.stopPropagation();
		this.isHeart = !this.isHeart;
		// this.showinterest.makePlayersInterest(1).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(data => {
		// })
	}

	public deleteUserVideo(videoId: number) {
		this.deleteVideo.emit(videoId);
	}

	public openEditVideo(data: IVideo) {
		console.log(data,"emmit");
		this.editVideo.emit(data);
	}

	onEvent(event: MouseEvent): void {
		event.stopPropagation();
	}

	public onPlayVideo(): void {
		this.visible = true;
	}

	public onVideoCartToggle(): void {
		this.visible = false;
	}
}
