import { isPlatformBrowser } from "@angular/common";
import {
	AfterViewInit,
	ElementRef,
	EventEmitter,
	Inject,
	NgZone,
	OnDestroy,
	Output,
	PLATFORM_ID,
} from "@angular/core";
import { Directive, Input } from "@angular/core";
import { Subscription, filter, fromEvent } from "rxjs";

@Directive({
	selector: "[appClickOutside]",
})
export class ClickOutsideDirective implements AfterViewInit, OnDestroy {
	constructor(
		private readonly elementReference: ElementRef<HTMLElement>,
		private readonly zone: NgZone,
		@Inject(PLATFORM_ID) private platform: object
	) {}

	@Input() appClickOutside: HTMLElement; // wanted element container
	@Output() clickOutsideEmitter = new EventEmitter<void>(); // emitting clicks

	// to avoid initial running, same for thse first click,
	// because the first click will open the menu
	isFirstTime: boolean = true;

	private destroySubscribtion = new Subscription(); // clicks listener

	ngAfterViewInit(): void {
		const element =
			this.appClickOutside || this.elementReference.nativeElement;
		this.zone.runOutsideAngular(() => {
			// To avoid unnecessary change detection cycles :D
			if (isPlatformBrowser(this.platform)) {
				const documentClick$ = fromEvent<MouseEvent>(document, "click");
				const subscription = documentClick$
					.pipe(
						filter(
							() =>
								!this.isFirstTime || (this.isFirstTime = false)
						),
						filter(
							(clickEvent) =>
								!element.contains(clickEvent.target as Node)
						)
					)
					.subscribe(() => {
						this.zone.run(() => {
							this.clickOutsideEmitter.emit();
						});
					});
				this.destroySubscribtion = subscription;
			}
		});
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		this.destroySubscribtion.unsubscribe();
	}
}
